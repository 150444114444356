import React, { useEffect, useState } from "react";
import { faChartLine } from "@fortawesome/free-solid-svg-icons";
import { Col, Row } from "@themesberg/react-bootstrap";
import axios from "axios";
import { CounterWidget } from "../../components/Widgets";
import { getToken, BASE_URL } from "../../data/common";
import { ReservationsTable } from "../../components/Tables";

export default () => {
  const [reservationCount, setReservationsCount] = useState(0);
  const [listingCount, setListingCount] = useState(0);
  const [ownersCount, setOwnersCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const token = getToken();

    setIsLoading(true);
    const getReservationCount = axios.get(
      `${BASE_URL}/users/reservations?limit=${1}&skip=${0}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const getOwnersCount = axios.get(`${BASE_URL}/users/owners`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const getListingsCount = axios.get(
      `${BASE_URL}/users/reservations/lisiting/`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    Promise.all([getReservationCount, getOwnersCount, getListingsCount])
      .then((resp) => {
        setReservationsCount(resp[0].data.data.count);
        setOwnersCount(resp[1].data.data.length);
        setListingCount(resp[2].data.data.count);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        // alert("Something went wrong");
      });
  }, []);
  return (
    <>
      {isLoading ? (
        <p>Loading Dashboard stats...</p>
      ) : (
        <div>
          <Row className="justify-content-md-left mt-5">
            <Col xs={12} sm={6} xl={4} className="mb-4">
              <CounterWidget
                category="Total listings"
                title={listingCount}
                period=""
                icon={faChartLine}
                iconColor="shape-secondary"
              />
            </Col>

            <Col xs={12} sm={6} xl={4} className="mb-4">
              <CounterWidget
                category="Total Owners"
                title={ownersCount}
                period=""
                icon={faChartLine}
                iconColor="shape-secondary"
              />
            </Col>

            <Col xs={12} sm={6} xl={4} className="mb-4">
              <CounterWidget
                category="Reservations"
                title={reservationCount}
                period=""
                icon={faChartLine}
                iconColor="shape-secondary"
              />
            </Col>
          </Row>
        </div>
      )}

      <p style={{fontWeight:'bolder'}}>Reservations</p>
      <ReservationsTable />
    </>
  );
};
