import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faAngleUp,
  faArrowDown,
  faArrowUp,
  faEdit,
  faEllipsisH,
  faExternalLinkAlt,
  faEye,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Nav,
  Card,
  Image,
  Button,
  Table,
  Dropdown,
  ProgressBar,
  Pagination,
  ButtonGroup,
  Modal,
} from "@themesberg/react-bootstrap";
import { Link } from "react-router-dom";

import { AppRoutes } from "../routes";
import { pageVisits, pageTraffic, pageRanking } from "../data/tables";
import transactions from "../data/transactions";
import commands from "../data/commands";
import { BASE_URL, getToken, GUESTY_BASE_URL } from "../data/common";
import axios from "axios";

const ValueChange = ({ value, suffix }) => {
  const valueIcon = value < 0 ? faAngleDown : faAngleUp;
  const valueTxtColor = value < 0 ? "text-danger" : "text-success";

  return value ? (
    <span className={valueTxtColor}>
      <FontAwesomeIcon icon={valueIcon} />
      <span className="fw-bold ms-1">
        {Math.abs(value)}
        {suffix}
      </span>
    </span>
  ) : (
    "--"
  );
};

export const PageVisitsTable = () => {
  const TableRow = (props) => {
    const { pageName, views, returnValue, bounceRate } = props;
    const bounceIcon = bounceRate < 0 ? faArrowDown : faArrowUp;
    const bounceTxtColor = bounceRate < 0 ? "text-danger" : "text-success";

    return (
      <tr>
        <th scope="row">{pageName}</th>
        <td>{views}</td>
        <td>${returnValue}</td>
        <td>
          <FontAwesomeIcon
            icon={bounceIcon}
            className={`${bounceTxtColor} me-3`}
          />
          {Math.abs(bounceRate)}%
        </td>
      </tr>
    );
  };

  return (
    <Card border="light" className="shadow-sm">
      <Card.Header>
        <Row className="align-items-center">
          <Col>
            <h5>Page visits</h5>
          </Col>
          <Col className="text-end">
            <Button variant="secondary" size="sm">
              See all
            </Button>
          </Col>
        </Row>
      </Card.Header>
      <Table responsive className="align-items-center table-flush">
        <thead className="thead-light">
          <tr>
            <th scope="col">Page name</th>
            <th scope="col">Page Views</th>
            <th scope="col">Page Value</th>
            <th scope="col">Bounce rate</th>
          </tr>
        </thead>
        <tbody>
          {pageVisits.map((pv) => (
            <TableRow key={`page-visit-${pv.id}`} {...pv} />
          ))}
        </tbody>
      </Table>
    </Card>
  );
};

export const PageTrafficTable = () => {
  const TableRow = (props) => {
    const {
      id,
      source,
      sourceIcon,
      sourceIconColor,
      sourceType,
      category,
      rank,
      trafficShare,
      change,
    } = props;

    return (
      <tr>
        <td>
          <Card.Link href="#" className="text-primary fw-bold">
            {id}
          </Card.Link>
        </td>
        <td className="fw-bold">
          <FontAwesomeIcon
            icon={sourceIcon}
            className={`icon icon-xs text-${sourceIconColor} w-30`}
          />
          {source}
        </td>
        <td>{sourceType}</td>
        <td>{category ? category : "--"}</td>
        <td>{rank ? rank : "--"}</td>
        <td>
          <Row className="d-flex align-items-center">
            <Col xs={12} xl={2} className="px-0">
              <small className="fw-bold">{trafficShare}%</small>
            </Col>
            <Col xs={12} xl={10} className="px-0 px-xl-1">
              <ProgressBar
                variant="primary"
                className="progress-lg mb-0"
                now={trafficShare}
                min={0}
                max={100}
              />
            </Col>
          </Row>
        </td>
        <td>
          <ValueChange value={change} suffix="%" />
        </td>
      </tr>
    );
  };

  return (
    <Card border="light" className="shadow-sm mb-4">
      <Card.Body className="pb-0">
        <Table responsive className="table-centered table-nowrap rounded mb-0">
          <thead className="thead-light">
            <tr>
              <th className="border-0">#</th>
              <th className="border-0">Traffic Source</th>
              <th className="border-0">Source Type</th>
              <th className="border-0">Category</th>
              <th className="border-0">Global Rank</th>
              <th className="border-0">Traffic Share</th>
              <th className="border-0">Change</th>
            </tr>
          </thead>
          <tbody>
            {pageTraffic.map((pt) => (
              <TableRow key={`page-traffic-${pt.id}`} {...pt} />
            ))}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

export const RankingTable = () => {
  const TableRow = (props) => {
    const {
      country,
      countryImage,
      overallRank,
      overallRankChange,
      travelRank,
      travelRankChange,
      widgetsRank,
      widgetsRankChange,
    } = props;

    return (
      <tr>
        <td className="border-0">
          <Card.Link href="#" className="d-flex align-items-center">
            <Image
              src={countryImage}
              className="image-small rounded-circle me-2"
            />
            <div>
              <span className="h6">{country}</span>
            </div>
          </Card.Link>
        </td>
        <td className="fw-bold border-0">{overallRank ? overallRank : "-"}</td>
        <td className="border-0">
          <ValueChange value={overallRankChange} />
        </td>
        <td className="fw-bold border-0">{travelRank ? travelRank : "-"}</td>
        <td className="border-0">
          <ValueChange value={travelRankChange} />
        </td>
        <td className="fw-bold border-0">{widgetsRank ? widgetsRank : "-"}</td>
        <td className="border-0">
          <ValueChange value={widgetsRankChange} />
        </td>
      </tr>
    );
  };

  return (
    <Card border="light" className="shadow-sm">
      <Card.Body className="pb-0">
        <Table responsive className="table-centered table-nowrap rounded mb-0">
          <thead className="thead-light">
            <tr>
              <th className="border-0">Country</th>
              <th className="border-0">All</th>
              <th className="border-0">All Change</th>
              <th className="border-0">Travel & Local</th>
              <th className="border-0">Travel & Local Change</th>
              <th className="border-0">Widgets</th>
              <th className="border-0">Widgets Change</th>
            </tr>
          </thead>
          <tbody>
            {pageRanking.map((r) => (
              <TableRow key={`ranking-${r.id}`} {...r} />
            ))}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

export const TransactionsTable = () => {
  const totalTransactions = transactions.length;
  const [owners, setOwners] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    const token = getToken();
    axios
      .get(`${BASE_URL}/users/owners`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setIsLoading(false);
        setOwners(response.data.data);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
        setOwners([])
      });
  }, []);

  const TableRow = (props) => {
    const {
      _id,
      ownersPortalSettings,
      listings,
      locale,
      active,
      allowReservations,
      showReservationTooltips,
      workingCapital,
      accountId,
      firstName,
      lastName,
      email,
      phone,
      createdAt,
      fullName,
      allowReservationsUnavailableDates,
    } = props;

    const handleSaveReservationsData = () => {
      localStorage.setItem(
        "reservations",
        JSON.stringify({
          owner: fullName,
          id: _id,
        })
      );
    };

    return (
      <tr>
        <td>
          <Card.Link
            onClick={handleSaveReservationsData}
            as={Link}
            to={AppRoutes.Reservations.path}
            className="fw-normal"
          >
            {accountId.substring(0, 5)}
          </Card.Link>
        </td>
        <td>
          <Card.Link
            onClick={handleSaveReservationsData}
            as={Link}
            to={AppRoutes.Reservations.path}
            className="fw-normal"
          >
            <span className="fw-normal">{firstName}</span>
          </Card.Link>
        </td>
        <td>
          <Card.Link
            onClick={handleSaveReservationsData}
            as={Link}
            to={AppRoutes.Reservations.path}
            className="fw-normal"
          >
            <span className="fw-normal">{lastName}</span>
          </Card.Link>
        </td>
        <td>
          <Card.Link
            onClick={handleSaveReservationsData}
            as={Link}
            to={AppRoutes.Reservations.path}
            className="fw-normal"
          >
            <span className="fw-normal">{email}</span>
          </Card.Link>
        </td>

        <td>
          <Card.Link
            onClick={handleSaveReservationsData}
            as={Link}
            to={AppRoutes.Reservations.path}
            className="fw-normal"
          >
            <span className="fw-normal">{phone}</span>
          </Card.Link>
        </td>

        <td>
          <span
            className={`fw-normal ${active ? "text-success" : "text-danger"}`}
          >
            {active ? "Active" : "Not active"}
          </span>
        </td>
        <td>
          <span className="fw-normal">{createdAt.substring(0, 10)}</span>
        </td>
      </tr>
    );
  };

  return (
    <Card border="light" className="table-wrapper table-responsive shadow-sm">
      <Card.Body className="pt-0">
        {isLoading ? (
          <div className="user-table align-items-center mt-5">
            {" "}
            <p>Loading...</p>
          </div>
        ) : (
          <Table hover className="user-table align-items-center">
            <thead>
              <tr>
                <th className="border-bottom">#</th>
                <th className="border-bottom">First Name</th>
                <th className="border-bottom">Last Name</th>
                <th className="border-bottom">Email</th>
                <th className="border-bottom">phone</th>
                <th className="border-bottom">Status</th>
                <th className="border-bottom">Created at</th>
              </tr>
            </thead>
            <tbody>
              {owners &&
                owners.map((t) => (
                  <TableRow key={`transaction-${t._id}`} {...t} />
                ))}
            </tbody>
          </Table>
        )}
        {/* <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
          <Nav>
            <Pagination className="mb-2 mb-lg-0">
              <Pagination.Prev>Previous</Pagination.Prev>
              <Pagination.Item active>1</Pagination.Item>
              <Pagination.Item>2</Pagination.Item>
              <Pagination.Item>3</Pagination.Item>
              <Pagination.Item>4</Pagination.Item>
              <Pagination.Item>5</Pagination.Item>
              <Pagination.Next>Next</Pagination.Next>
            </Pagination>
          </Nav>
          <small className="fw-bold">
            Showing <b>{owners.length}</b> out of <b>25</b> entries
          </small>
        </Card.Footer> */}
      </Card.Body>
    </Card>
  );
};

export const ReservationsTable = () => {
  const totalTransactions = transactions.length;
  const [reservations, setReservations] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [bookings, setBookings] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  //Owner
  const [owner, setOwner] = useState({});
  const [showOwnerModal, setShowOwnerModal] = useState(false);
  const [isLoadingOwner, setIsLoadingOwner] = useState(true);

  //Listing
  const [listing, setListing] = useState({});
  const [showListingModal, setshowListingModal] = useState(false);
  const [isLoadingLisiting, setIsLoadingListing] = useState(true);

  //Listing
  const [price, setPrice] = useState({});
  const [showPriceModal, setshowPriceModal] = useState(false);
  const [isLoadingPrice, setIsLoadingPrice] = useState(true);
  const handleClose = () => {
    setShowOwnerModal(false);
    setshowListingModal(false);
    setshowPriceModal(false)
  };

  useEffect(() => {
    setIsLoading(true);
    const token = getToken();
    axios
      .get(
        `${BASE_URL}/users/reservations?limit=${25}&skip=${
          (currentPage - 1) * 25
        }`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setIsLoading(false);
        setTotalPages(Math.ceil(response.data.data.count));
        setReservations(response.data.data.results);
      })
      .catch((error) => {
        setReservations([])        
        setIsLoading(false);
      });
  }, [currentPage]);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const TableRow = (props) => {
    const {
      _id,
      integration,
      listingId,
      accountId,
      checkIn,
      checkOut,
      guest,
      confirmationCode,
      guestId,
      listing,
    } = props;

    const handleSaveReservationsData = () => {
      // localStorage.setItem(
      //   "reservations",
      //   JSON.stringify({
      //     owner: fullName,
      //     id: _id,
      //   })
      // );
    };

    const handleViewGuesty = (id) => {};
    const getOwner = (id) => {
      const token = getToken();
      setIsLoadingOwner(true);
      setShowOwnerModal(true);
      axios
        .get(`${BASE_URL}/users/reservations/owner/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setIsLoadingOwner(false);
          setOwner(response.data);
        })
        .catch((error) => {
          setIsLoadingOwner(false);
          setShowOwnerModal(false);
          return {};
        });
    };

    const handleFetchListing = (id) => {
      const token = getToken();
      setIsLoadingListing(true);
      setshowListingModal(true);
      axios
        .get(`${BASE_URL}/users/reservations/lisiting/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setIsLoadingListing(false);
          setListing(response.data.data);
        })
        .catch((error) => {
          setIsLoadingListing(false);
          setshowListingModal(false);
          return {};
        });
    };

    const handlePriceListing = (id) => {
      const token = getToken();
      setIsLoadingPrice(true);
      setshowPriceModal(true);
      axios
        .get(`${BASE_URL}/users/reservations/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setIsLoadingPrice(false);
          setPrice(response.data.data.money);
        })
        .catch((error) => {
          setIsLoadingPrice(false);
          setshowPriceModal(false);
          return {};
        });
    };

    return (
      <tr>
        <td>
          <Card.Link
            onClick={handleSaveReservationsData}
            as={Link}
            to={AppRoutes.Reservations.path}
            className="fw-normal"
          >
            {listingId.substring(0, 5)}
          </Card.Link>
        </td>
        <td>
          <Card.Link
            onClick={handleSaveReservationsData}
            as={Link}
            to={AppRoutes.Reservations.path}
            className="fw-normal"
          >
            <span className="fw-normal">{guest.fullName}</span>
          </Card.Link>
        </td>
        <td>
          <Card.Link
            onClick={handleSaveReservationsData}
            as={Link}
            to={AppRoutes.Reservations.path}
            className="fw-normal"
          >
            <span className="fw-normal">{checkIn.substring(0, 10)}</span>
          </Card.Link>
        </td>
        <td>
          <Card.Link
            onClick={handleSaveReservationsData}
            as={Link}
            to={AppRoutes.Reservations.path}
            className="fw-normal"
          >
            <span className="fw-normal">{checkOut.substring(0, 10)}</span>
          </Card.Link>
        </td>

        <td>
          <Card.Link
            onClick={handleSaveReservationsData}
            as={Link}
            to={AppRoutes.Reservations.path}
            className="fw-normal"
          >
            <span className="fw-normal">{confirmationCode}</span>
          </Card.Link>
        </td>

        <td>
          <Card.Link
            onClick={() => getOwner(listing._id)}
            to={AppRoutes.Reservations.path}
            className="fw-normal"
          >
            <span className="fw-normal" style={{ textDecoration: "underline" }}>
              View owner
            </span>
          </Card.Link>
        </td>

        <td>
          <span
            className={`fw-normal"}`}
            style={{ textDecoration: "underline" }}
            onClick={() => handleFetchListing(listing._id)}
          >
            <a> View listing</a>
            {/* <a href={`https://app.guesty.com/properties/${listing._id}`} rel="noopener noreferrer" target='_blank'>{listing.title}</a> */}
          </span>
        </td>
        <td>
          <span
            className={`fw-normal"}`}
            onClick={() => handlePriceListing(_id)}
            style={{ textDecoration: "underline" }}
          >
            <a>View more</a>
          </span>
        </td>
        <td>
          <span className="fw-normal">
            {integration.platform.toUpperCase()}
          </span>
        </td>
      </tr>
    );
  };

  return (
    <Card border="light" className="table-wrapper table-responsive shadow-sm">
      <Card.Body className="pt-0">
        {isLoading ? (
          <div className="user-table align-items-center mt-5">
            {" "}
            <p>Loading...</p>
          </div>
        ) : (
          <div>
            <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
              <Nav>
                <Pagination className="mb-2 mb-lg-0">
                  <Pagination.Prev onClick={handlePrevPage}>
                    Previous
                  </Pagination.Prev>
                  <Pagination.Next onClick={handleNextPage}>
                    Next
                  </Pagination.Next>
                </Pagination>
              </Nav>
              <small className="fw-bold">
                Showing <b>{currentPage * 25}</b> out of <b> {totalPages}</b>{" "}
                entries
              </small>
            </Card.Footer>

            <Table hover className="user-table align-items-center">
              <thead>
                <tr>
                  <th className="border-bottom">#</th>
                  <th className="border-bottom">Guest</th>
                  <th className="border-bottom">Check in</th>
                  <th className="border-bottom">Check out</th>
                  <th className="border-bottom">Reservation code</th>
                  <th className="border-bottom">Owner</th>
                  <th className="border-bottom">Listing</th>
                  <th className="border-bottom">Price</th>
                  <th className="border-bottom">OTA</th>
                </tr>
              </thead>
              <tbody>
                {reservations &&
                  reservations.map((t) => (
                    <TableRow key={`transaction-${t.listingId}`} {...t} />
                  ))}
              </tbody>
            </Table>
          </div>
        )}
        <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
          <Nav>
            <Pagination className="mb-2 mb-lg-0">
              <Pagination.Prev onClick={handlePrevPage}>
                Previous
              </Pagination.Prev>
              <Pagination.Next onClick={handleNextPage}>Next</Pagination.Next>
            </Pagination>
          </Nav>
          <small className="fw-bold">
            Showing <b>{currentPage * 25}</b> out of <b> {totalPages}</b>{" "}
            entries
          </small>
        </Card.Footer>
      </Card.Body>

      {/* Owner Modal */}
      <React.Fragment>
        <Modal
          as={Modal.Dialog}
          centered
          show={showOwnerModal}
          onHide={handleClose}
        >
          <Modal.Header>
            <Modal.Title className="h6">Owner</Modal.Title>
            <Button variant="close" aria-label="Close" onClick={handleClose} />
          </Modal.Header>
          <Modal.Body>
            {isLoadingOwner ? (
              <p>Loading...</p>
            ) : (
              <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: 20,
                    marginRight: 20,
                    justifyContent: "space-between",
                  }}
                >
                  <p>Name: </p>
                  <p>{owner && owner.fullName ? owner.fullName : "N/A"}</p>
                </div>
                <hr />

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: 20,
                    marginRight: 20,
                    justifyContent: "space-between",
                  }}
                >
                  <p>Email: </p>
                  <p>{owner && owner.email ? owner.email : "N/A"}</p>
                </div>
                <hr />

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: 20,
                    marginRight: 20,
                    justifyContent: "space-between",
                  }}
                >
                  <p>Phone: </p>
                  <p>{owner && owner.phone ? owner.phone : "N/A"}</p>
                </div>

                <hr />

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: 20,
                    marginRight: 20,
                    justifyContent: "space-between",
                  }}
                >
                  <p>Allow Reservations: </p>
                  <p>{owner && owner.allowReservations ? "YES" : "NO"}</p>
                </div>
              </div>
            )}
          </Modal.Body>

          <Modal.Footer>
            {isLoadingOwner && owner == null ? null : (
              <Button
                variant="secondary"
                onClick={() => {
                  window.open(
                    `https://app.guesty.com/contact/owner/${owner.id}`,
                    "_blank"
                  );
                }}
              >
                View in Guesty
              </Button>
            )}
            <Button
              variant="link"
              className="text-gray ms-auto"
              onClick={handleClose}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>

      {/* Liting Modal */}
      <React.Fragment>
        <Modal
          as={Modal.Dialog}
          centered
          show={showListingModal}
          onHide={handleClose}
        >
          <Modal.Header>
            <Modal.Title className="h6">Listing Details</Modal.Title>
            <Button variant="close" aria-label="Close" onClick={handleClose} />
          </Modal.Header>
          <Modal.Body>
            {isLoadingLisiting ? (
              <p>Loading...</p>
            ) : (
              <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: 20,
                    marginRight: 20,
                    justifyContent: "space-between",
                  }}
                >
                  {/* <p>Title: </p> */}
                  <p>
                    <b>{listing && listing.title ? listing.title : "N/A"} </b>
                  </p>
                </div>
                <small
                  style={{ fontSize: 12, alignItems: "center", marginLeft: 95 }}
                  className="text-center"
                >
                  {listing && listing.address ? listing.address.full : "N/A"}{" "}
                </small>

                <hr />

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: 20,
                    marginRight: 20,
                    justifyContent: "space-between",
                  }}
                >
                  <p>Nickname: </p>
                  <p>
                    {listing && listing.nickname ? listing.nickname : "N/A"}
                  </p>
                </div>
                <hr />

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: 20,
                    marginRight: 20,
                    justifyContent: "space-between",
                  }}
                >
                  <p>Room Type: </p>
                  <p>
                    {listing && listing.roomType ? listing.roomType : "N/A"}
                  </p>
                </div>

                <hr />

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: 20,
                    marginRight: 20,
                    justifyContent: "space-between",
                  }}
                >
                  <p>Property Type: </p>
                  <p>
                    {listing && listing.propertyType
                      ? listing.propertyType
                      : "N/A"}
                  </p>
                </div>

                <hr />

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: 20,
                    marginRight: 20,
                    justifyContent: "space-between",
                  }}
                >
                  <p>Amenities: </p>
                  <p>{`${listing.beds} Beds, ${listing.bedrooms} Bedrooms, ${listing.bathrooms} Bathrooms`}</p>
                </div>
              </div>
            )}
          </Modal.Body>

          <Modal.Footer>
            {isLoadingOwner && owner == null ? null : (
              <Button
                variant="secondary"
                onClick={() => {
                  window.open(
                    `https://app.guesty.com/contact/owner/${owner.id}`,
                    "_blank"
                  );
                }}
              >
                View in Guesty
              </Button>
            )}
            <Button
              variant="link"
              className="text-gray ms-auto"
              onClick={handleClose}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>

      {/* Price modal */}

      <React.Fragment>
        <Modal
          as={Modal.Dialog}
          centered
          show={showPriceModal}
          onHide={handleClose}
        >
          <Modal.Header>
            <Modal.Title className="h6">Price</Modal.Title>
            <Button variant="close" aria-label="Close" onClick={handleClose} />
          </Modal.Header>
          <Modal.Body>
            {isLoadingPrice ? (
              <p>Loading...</p>
            ) : (
              <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: 20,
                    marginRight: 20,
                    justifyContent: "space-between",
                  }}
                >
                  <p>Price: </p>
                  <p>
                    $
                    {price && price.subTotalPrice ? price.subTotalPrice : "N/A"}
                  </p>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: 20,
                    marginRight: 20,
                    justifyContent: "space-between",
                  }}
                >
                  <p>Currency: </p>
                  <p>{price && price.currency ? price.currency : "N/A"}</p>
                </div>
                <hr />
                <p>Invoice</p>

                <Table bordered>
                  <thead>
                    <tr>
                      <th>Title</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {price.invoiceItems &&
                      price.invoiceItems.map((item) => (
                        <React.Fragment key={item._id}>
                          <tr>
                            <td>{item.title}</td>
                            <td>
                              {item.amount} {item.currency}
                            </td>
                          </tr>
                        </React.Fragment>
                      ))}
                  </tbody>
                </Table>
              </div>
            )}
          </Modal.Body>

          <Modal.Footer>
            <Button
              variant="link"
              className="text-gray ms-auto"
              onClick={handleClose}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    </Card>
  );
};

export const CommandsTable = () => {
  const TableRow = (props) => {
    const { name, usage = [], description, link } = props;

    return (
      <tr>
        <td className="border-0" style={{ width: "5%" }}>
          <code>{name}</code>
        </td>
        <td className="fw-bold border-0" style={{ width: "5%" }}>
          <ul className="ps-0">
            {usage.map((u) => (
              <ol key={u} className="ps-0">
                <code>{u}</code>
              </ol>
            ))}
          </ul>
        </td>
        <td className="border-0" style={{ width: "50%" }}>
          <pre className="m-0 p-0">{description}</pre>
        </td>
        <td className="border-0" style={{ width: "40%" }}>
          <pre>
            <Card.Link href={link} target="_blank">
              Read More{" "}
              <FontAwesomeIcon icon={faExternalLinkAlt} className="ms-1" />
            </Card.Link>
          </pre>
        </td>
      </tr>
    );
  };

  return (
    <Card border="light" className="shadow-sm">
      <Card.Body className="p-0">
        <Table
          responsive
          className="table-centered rounded"
          style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}
        >
          <thead className="thead-light">
            <tr>
              <th className="border-0" style={{ width: "5%" }}>
                Name
              </th>
              <th className="border-0" style={{ width: "5%" }}>
                Usage
              </th>
              <th className="border-0" style={{ width: "50%" }}>
                Description
              </th>
              <th className="border-0" style={{ width: "40%" }}>
                Extra
              </th>
            </tr>
          </thead>
          <tbody>
            {commands.map((c) => (
              <TableRow key={`command-${c.id}`} {...c} />
            ))}
          </tbody>

          <br />
        </Table>
      </Card.Body>
    </Card>
  );
};
