import axios from "axios";
// import dotenv from "dotenv";

// dotenv.config();
export const BASE_URL = "https://mit-back.onrender.com";

export const GUESTY_BASE_URL = "https://open-api.guesty.com/v1";

export const getToken = () => {
  try {
    const lastActionTime = localStorage.getItem("lastLogin");

    const user = localStorage.getItem("user");

    const userObj = JSON.parse(user);
    const currentTime = Date.now();
    const twoHours = 2 * 60 * 60 * 1000; // in milliseconds
    const timeDifference = currentTime - lastActionTime;
    console.log({ timeDifference, userObj, lastActionTime, currentTime })
    if (timeDifference >= twoHours) {
      try {
        axios
          .post(`${BASE_URL}/users/guesty-token`, {}, {
            headers: {
              Authorization: `Bearer ${userObj.token}`,
            },
          })
          .then((response) => {
            localStorage.setItem('lastLogin', new Date());
            localStorage.setItem("guesty_token", response.data.data);
            return userObj.token;
          })
          .catch((err) => {
            console.log({ err })
            window.location = "/#/login";
            localStorage.clear();
          });
      } catch (e) {
        window.location = "/#/login";
        localStorage.clear();
      }
    } else {
      // alert(localStorage.getItem('guesty_token'))
      return userObj.token;
    }
  } catch (e) { }
};

// export const get
