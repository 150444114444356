import React, { useEffect } from "react";
import { NotificationManager} from 'react-notifications';
 
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Card } from "@themesberg/react-bootstrap";
import { Link } from "react-router-dom";
import { AppRoutes } from "../routes";
import { useHistory } from "react-router-dom";
import { ReservationsTable } from "../components/Tables";

export default () => {
  const handleResetReservation = () => {
    localStorage.removeItem("reservations");
  };

//   const history = useHistory();
//   useEffect(() => {
//     const reservations = localStorage.getItem("reservations");
//     if(!reservations){
//         alert('Please select a reservation owner first')
//         history.push(Routes.Transactions.path);
//     }
//   }, [history]);
  return (
    <>
      <div className="table-settings mb-4">
        <Row className="justify-content-between align-items-center">
          <Col xs={8} md={6} lg={3} xl={4}>
            <p style={{ marginTop: 20, fontWeight: "bolder", marginLeft: 5 }}>
              Reservations
            </p>
          </Col>
        </Row>
      </div>

      <ReservationsTable />
    </>
  );
};
